import React, { useState, createContext } from 'react';

const SearchContext = createContext();

export function SearchProvider({ children }) {
	const [searchQuery, setSearchQuery] = useState('pest');
	const [searchTerm, setSearchTerm] = useState('');

	return (
		<SearchContext.Provider
			value={{ searchTerm, setSearchTerm, searchQuery, setSearchQuery }}>
			{children}
		</SearchContext.Provider>
	);
}

export default SearchContext;

import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import {
	AppBar,
	Grid,
	Typography,
	Container,
	Toolbar,
	Collapse,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Search } from './Search';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.white,
		color: theme.workwaveBlue,
		paddingBottom: '1rem',

		[theme.breakpoints.down('lg')]: {
			padding: '1rem 0',
		},
	},
	navLogo: {
		display: 'inline-block',
		marginTop: '18px',
		[theme.breakpoints.down('lg')]: {
			marginTop: '0',
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: '.75rem',
		},
		[theme.breakpoints.down('xs')]: {
			maxWidth: '200px',
			width: '200px',
			height: 'auto',
			marginBottom: '0',
		},
	},
	numbers: {
		marginTop: '1rem',
		marginRight: '1rem',
		fontSize: '16px',
		fontWeight: 600,
		color: theme.workwaveBlue,
		[theme.breakpoints.down('lg')]: {
			margin: '0',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 1rem',
		},
	},
	// numbersFirst: {
	// 	marginRight: '1rem',
	// },
	phoneIcon: {
		[theme.breakpoints.down('sm')]: {
			marginRight: '1.5rem',
		},
	},
	notMobile: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
}));

export const Nav = () => {
	const classes = useStyles();
	const [selected, setSelected] = useState(false);

	const handleSelect = (e) => {
		const name = e.currentTarget.getAttribute('name');
		if (name === selected) {
			setSelected(false);
		} else {
			setSelected(name);
		}
	};

	const { nav } = useStaticQuery(graphql`
		query {
			nav: sanityNavContent {
				title
				slug {
					current
				}
				tags
				salesNumber
				serviceNumber
				wwLogo {
					asset {
						url
						gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 300)
					}
				}
			}
		}
	`);

	const med = useMediaQuery('(max-width: 960px)');
	const sm = useMediaQuery('(max-width: 600px)');
	const xs = useMediaQuery('(max-width: 480px)');
	return (
		<>
			<AppBar position='sticky' className={classes.appBar}>
				<Toolbar>
					<Container className={classes.root} fixed>
						{sm ? (
							<>
								<Grid
									container
									direction='row'
									justify='space-between'
									alignItems='center'
									className={classes.mobile}>
									<Link to='/'>
										{/* {nav.wwLogo.asset.gatsbyImageData.images?.sources.length ? (
											<img
												className={classes.navLogo}
												src={nav.wwLogo.asset.url}
												style={{
													height: '4.5rem',
													width: '100%',
												}}
												alt='logo'
											/>
										) : ( */}
										<GatsbyImage
											className={classes.navLogo}
											image={nav.wwLogo.asset.gatsbyImageData}
											alt='logo'
										/>
										{/* )} */}
									</Link>
									<div
										style={{
											display: 'flex',
										}}>
										<FontAwesomeIcon
											className={classes.phoneIcon}
											style={{
												height: '1.5rem',
												width: '1.5rem',
											}}
											icon={['fad', 'phone-alt']}
											name='phone'
											onClick={(e) => handleSelect(e)}
										/>

										<FontAwesomeIcon
											style={{
												height: '1.5rem',
												width: '1.5rem',
											}}
											icon={['fad', 'search']}
											name='search'
											onClick={(e) => handleSelect(e)}
										/>
									</div>
								</Grid>

								<Collapse in={selected}>
									{selected ? (
										<Grid
											container
											item
											xs={12}
											justify='center'
											style={{ marginTop: '.5rem' }}
											direction='row'>
											{selected === 'phone' ? (
												<Grid container direction='column' alignItems='center'>
													<a
														href='tel: 866-794-1658'
														style={{ textDecoration: 'none' }}>
														<Typography className={classes.numbers}>
															<FontAwesomeIcon
																icon={['fad', 'phone-alt']}
																style={{ marginRight: '5px' }}
															/>
															Sales: {nav.salesNumber}
														</Typography>
													</a>
													{/* <a
														href='tel:  800-762-0301'
														style={{ textDecoration: 'none' }}>
														<Typography className={classes.numbers}>
															<FontAwesomeIcon
																icon={['fad', 'phone-alt']}
																style={{ marginRight: '5px' }}
															/>
															Support: {nav.serviceNumber}
														</Typography>
													</a> */}
												</Grid>
											) : (
												<Search />
											)}
										</Grid>
									) : (
										<Grid style={{ minHeight: xs ? '60px' : '30px' }} />
									)}
								</Collapse>
							</>
						) : (
							<Grid
								container
								direction='row'
								justify={med ? 'center' : 'space-between'}
								className={classes.notMobile}
								alignItems='center'>
								<Link to='/'>
									{/* {nav.wwLogo.asset.gatsbyImageData.images?.sources.length ? (
										<img
											className={classes.navLogo}
											src={nav.wwLogo.asset.url}
											style={{
												height: '4.5rem',
												width: '100%',
											}}
											alt='logo'
										/>
									) : ( */}
									<GatsbyImage
										className={classes.navLogo}
										image={nav.wwLogo.asset.gatsbyImageData}
										alt='logo'
									/>
									{/* )} */}
								</Link>

								<Grid
									container
									item
									xs={12}
									md={6}
									lg={5}
									direction='row'
									alignItems='flex-end'
									justify={med ? 'center' : 'flex-end'}>
									<Grid
										item
										container
										direction='row'
										xs={12}
										justify={med ? 'center' : 'flex-end'}
										alignItems='center'
										style={{ paddingBottom: '4px' }}>
										<a
											className={classes.numbersFirst}
											href='tel: 866-794-1658'
											style={{ textDecoration: 'none' }}>
											<Typography className={classes.numbers}>
												<FontAwesomeIcon icon={['fad', 'phone-alt']} /> Sales:{' '}
												{nav.salesNumber}
											</Typography>
										</a>
										{/* <a
											href='tel:  800-762-0301'
											style={{ textDecoration: 'none' }}>
											<Typography className={classes.numbers}>
												<FontAwesomeIcon icon={['fad', 'phone-alt']} /> Support:{' '}
												{nav.serviceNumber}
											</Typography>
										</a> */}
									</Grid>
									<Search />
								</Grid>
							</Grid>
						)}
					</Container>
				</Toolbar>
			</AppBar>
		</>
	);
};

import React, { useState, createContext } from 'react';

const PardotContext = createContext();

export function PardotProvider({ children }) {
	const [utm, setUtm] = useState('');
	return (
		<PardotContext.Provider value={{ utm, setUtm }}>
			{children}
		</PardotContext.Provider>
	);
}

export default PardotContext;

import React, { useState, createContext } from 'react';

const FormContext = createContext();

export function FormProvider({ children }) {
	const [type, setType] = useState('');
	const [slug, setSlug] = useState('');
	const [submitted, setSubmitted] = useState(false);
	return (
		<FormContext.Provider
			value={{ type, setType, slug, setSlug, submitted, setSubmitted }}>
			{children}
		</FormContext.Provider>
	);
}

export default FormContext;

import React, { useState, useEffect, createContext } from 'react';
import jwt_decode from 'jwt-decode';

const TokenContext = createContext();

export function TokenProvider({ children }) {
	const [token, setToken] = useState(null);
	const [user, setUser] = useState(null);
	useEffect(() => {
		//on mount, will check for user token and set it to state and pass it down to filters
		const customize = async () => {
			try {
				const decode = await setUser(jwt_decode(token));
			} catch (err) {
				return;
			}
		};

		customize();
	}, [token]);

	return (
		<TokenContext.Provider value={{ user, setToken }}>
			{children}
		</TokenContext.Provider>
	);
}

export default TokenContext;

import React from 'react';
import { Layout } from './src/components/Layout/Layout';
import { SearchProvider } from './src/context/SearchContext';
import { TokenProvider } from './src/context/TokenContext';
import { FormProvider } from './src/context/FormContext';
import { IpProvider } from './src/context/IpContext';
import './src/cookie-control/styles.css';
import { PardotProvider } from './src/context/PardotContext';

// import 'typeface-poppins';

export function wrapPageElement({ element, props }) {
	return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
	return (
		<PardotProvider>
			<IpProvider>
				<TokenProvider>
					<FormProvider>
						<SearchProvider>{element}</SearchProvider>
					</FormProvider>
				</TokenProvider>
			</IpProvider>
		</PardotProvider>
	);
}

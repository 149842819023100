import React, { useState, useEffect, createContext } from 'react';
import publicIp from 'public-ip';
import axios from 'axios';

const IpContext = createContext();

export function IpProvider({ children }) {
	const [ip, setIp] = useState(null);
	const [user, setUser] = useState(null);

	useEffect(() => {
		const getClientIp = async () => {
			try {
				const response = await publicIp.v4({
					fallbackUrls: ['https://ifconfig.co/ip'],
				});
				setIp(response);
			} catch (err) {
				return;
			}
		};

		getClientIp();
	}, []);

	//trying to use this for dynamic data purposes.

	// useEffect(() => {
	// 	const getIp = async () => {
	// 		try {
	// 			const response = await axios.get(
	// 				`https://api.ipdata.co/${ip}/asn?api-key=c9095ca5fdd49abe312e94ab43c80e2e7fa8e1a9a2cd5973cd4823a2`
	// 			);
	// 			setUser(response.data);
	// 		} catch (err) {
	// 			console.log(err);
	// 		}
	// 	};

	// 	!user && getIp();
	// }, [ip]);

	return <IpContext.Provider value={{}}>{children}</IpContext.Provider>;
}

export default IpContext;
